import React from 'react'
import MusicMarquee from '../components/marquee'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const About = ({ data: { about } }) => (
  <>
    <article className="sheet">
      <HelmetDatoCms seo={about.seoMetaTags} />
      <div className="sheet__inner">        
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: about.bioNode.childMarkdownRemark.html,
          }}
        />
      </div>
      <a className="tom__jerry" href={"https://youtu.be/RIiNxIE_rWc?si=qnGHC7iLrf98niN2&t=31"} target="_blank">
        <img src={"https://www.datocms-assets.com/21907/1712136736-tomjerry.gif"} />
      </a>
    </article>
    <MusicMarquee className={`visual-aid marquee`} />
  </>
)

export default About

export const query = graphql`
  query AboutQuery {
    about: datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      photo {
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      bioNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
