import React from 'react';

const MusicMarquee = () => {
  const names = [
    'DJ SLING',
    'PARDO',
    'HUGO CAPABLANCA',
    'CHRISTIAN DI VITO',
    'JOE BEEDLES',
    'CONAL BLAKE',
    'RUPERT CLERVAUX',
    'REBECCA SALVADORI',
    'HENERICO ROSSI',
    'KEZURGH',
    'STELLAGE',
    'HARDWAX',
    'FLATY',
    'OUT OF JOINTS',
    'TOME RECORDS',
    'TIME COW',
    'FROZEN REEDS',
    'SAMO DJ',
    'WILL BANKHEAD',
    'SDEM',
    'ALIEN JAMS',
    'SAFECRACKERS',
    'BEAUTIFUL SWIMMERS',
    'JACK CALLAHAN',
    'MONEY LANG',
    'LAURA NOT',
    'GUY BREWER',
    'PESSIMIST',
    'GHOST PHONE',
    'BRIAN LEEDS',
    'MATT DAMHAVE',
    'DNTEL',
    'JUSTIN TRIPP',
    'BLACK LODGE',
    'ANDRY',
    'SYCH HACKERS',
    'BAMBOUNOU',
    'BEN VINCE',
    'DJ OCTOBER',
    'J. ALBERT',
  ];

  return (
    <div className={`marquee visual-aid`}>
      <div>
        <div>
          {names.map((name, index) => (
            <React.Fragment key={index}>{name}<br /></React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MusicMarquee;